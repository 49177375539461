import 'stacktable.js'

import MicroModal from 'micromodal'
import Swiper from 'swiper'
import { Navigation } from 'swiper/modules'
import VenoBox from 'venobox'

function handleSpecificScripts() {
  // Add stacktable class for tables
  const tables = document.querySelectorAll('div.entry-content table:not([class*="nsn-"])')

  tables.forEach((table) => {
    table.classList.add('init-stacktable')
  })
  if (tables.length > 0) {
    jQuery('.init-stacktable').stacktable()
  }
  const modalNotRecommended = document.querySelector('#modal-not-recommended')
  if (modalNotRecommended) {
    // Prevent escape key from closing the modal
    // Must be done before MicroModal.init()
    document.addEventListener('keydown', (e) => {
      if (e.key === 'Escape' && modalNotRecommended.classList.contains('is-open')) {
        e.stopImmediatePropagation()
        return false
      }
    })

    MicroModal.init()
    MicroModal.show('modal-not-recommended', {
      disableScroll: true,
      closeTrigger: '',
      debugMode: true
    })

    const modalContent = modalNotRecommended.querySelector('.not-recommended-coupons')
    if (modalContent) {
      const modalContentHeight = modalContent.offsetHeight
      const headerHeight = document.querySelector('#theme-header').offsetHeight

      if (modalContentHeight > window.innerHeight - headerHeight * 2) {
        modalNotRecommended.classList.add('oversize')
      }
    }
  }

  // Swiper how to
  // eslint-disable-next-line no-unused-vars
  const swiperHowTo = new Swiper('.how-to-steps', {
    modules: [Navigation],
    slidesPerView: 1,
    spaceBetween: 16,
    autoHeight: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    history: {
      enabled: false
    },
    breakpoints: {
      992: {
        slidesPerView: 2
      }
    }
  })
  if (window.matchMedia('(max-width: 767px)').matches) {
    // eslint-disable-next-line no-unused-vars
    const swipernotrecommended = new Swiper('.not-recommended-coupons.multiple-coupons .coupons', {
      modules: [Navigation],
      slidesPerView: 1,
      spaceBetween: 24,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      history: {
        enabled: false
      },
      on: {
        reachEnd: function () {
          const swiperCoupons = document.querySelectorAll('.not-recommended-coupons.multiple-coupons .coupons')
          if (swiperCoupons) {
            swiperCoupons.forEach((swiperCoupon) => {
              swiperCoupon.classList.add('reached-end')
            })
          }
        },
        reachBeginning: function () {
          const swiperCoupons = document.querySelectorAll('.not-recommended-coupons.multiple-coupons .coupons')
          if (swiperCoupons) {
            swiperCoupons.forEach((swiperCoupon) => {
              swiperCoupon.classList.add('reached-beginning')
            })
          }
        },
        slideChange: function () {
          if (!this.isEnd) {
            const swiperCoupons = document.querySelectorAll('.not-recommended-coupons.multiple-coupons .coupons')
            if (swiperCoupons) {
              swiperCoupons.forEach((swiperCoupon) => {
                swiperCoupon.classList.remove('reached-end')
              })
            }
          }
          if (!this.isBeginning) {
            const swiperCoupons = document.querySelectorAll('.not-recommended-coupons.multiple-coupons .coupons')
            if (swiperCoupons) {
              swiperCoupons.forEach((swiperCoupon) => {
                swiperCoupon.classList.remove('reached-beginning')
              })
            }
          }
        }
      }
    })
  } else {
    const swiperbtnsPrev = document.querySelectorAll('.not-recommended-coupons .coupons .swiper-button-prev')
    if (swiperbtnsPrev) {
      swiperbtnsPrev.forEach((swiperPrev) => {
        swiperPrev.remove()
      })
    }
    const swiperbtnsNext = document.querySelectorAll('.not-recommended-coupons .coupons .swiper-button-next')
    if (swiperbtnsNext) {
      swiperbtnsNext.forEach((swiperNext) => {
        swiperNext.remove()
      })
    }

    const fragment = document.createDocumentFragment()
    const elements = document.querySelectorAll('.not-recommended-coupons .coupons .swiper-wrapper')
    if (elements) {
      elements.forEach((element) => {
        while (element.firstChild) {
          fragment.appendChild(element.firstChild)
        }
        element.parentNode.replaceChild(fragment, element)
      })
    }
  }

  setTimeout(() => {
    const couponBonusV4 = document.querySelectorAll('[class*="cstbv4-container"]')
    if (couponBonusV4) {
      if (window.matchMedia('(max-width: 767px)').matches) {
        couponBonusV4.forEach((couponBonus) => {
          const bonusInfos = couponBonus.querySelector('[class$="cstbv4-bonus-infos"]')
          const logoRating = couponBonus.querySelector('[class$="cstbv4-lrf"]')

          if (bonusInfos && logoRating) {
            logoRating.appendChild(bonusInfos)
          }
        })
      }
    }
  }, 100)

  const tipsodd = document.querySelectorAll('.gtm-tips')
  if (tipsodd) {
    tipsodd.forEach((odd) => {
      if (odd.childElementCount === 1) {
        odd.classList.add('one-odd')
      } else if (odd.childElementCount === 3) {
        const classesToAdd = ['multiple-odd', 'three-odd']
        odd.classList.add(...classesToAdd)
      } else if (odd.childElementCount > 1) {
        odd.classList.add('multiple-odd')
      }
    })
  }

  // eslint-disable-next-line no-unused-vars
  const venobox = new VenoBox({
    selector: '.app-images',
    numeration: true,
    infinigall: true,
    spinner: 'pulse'
  })
}

if (document.readyState !== 'loading') {
  handleSpecificScripts()
} else {
  document.addEventListener('DOMContentLoaded', handleSpecificScripts)
}
